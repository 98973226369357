import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Project } from './Components';
import { Signature3D } from './Icons';
import { ReactComponent as EmojiSmile } from './img/svg/EmojiSmile.svg';
import { ReactComponent as Star } from './img/svg/Star.svg';
import alias from './img/thumbnails/alias.png';
import onboarding from './img/thumbnails/onboarding.png';
import panel from './img/thumbnails/panel.png';
import tab from './img/thumbnails/tab.png';
import { ContentsContainer, PageEnd } from './Layout';

const TextTypingOnScroll = ({ progressRate, setNumFrames }, ref) => {
    const frames1 = 'My work is ';
    const frames2 = 'password-protected.';
    const frames3 =
        "If you don't have a password, please reach out to me to continue exploring my portfolio!";
    const frames4 = '           '; // Empty frames for buffer between scroll effect & Projects section

    const totalNumFrames =
        frames1.length + frames2.length + frames3.length + frames4.length;

    const [currentFrameIndex, setCurrentFrameIndex] = useState(0);

    useLayoutEffect(() => {
        setNumFrames(totalNumFrames);
    }, []);

    useEffect(() => {
        setCurrentFrameIndex(Math.floor(totalNumFrames * progressRate) / 100);
    }, [progressRate]);

    return (
        <>
            <div className="col-auto">
                <Star />
            </div>
            <div className="col lh-1833">
                {frames1.substring(0, currentFrameIndex)}
                <span className="fw-bold highlight">
                    {frames2.substring(0, currentFrameIndex - frames1.length)}
                </span>
                <br />
                <span className="text-gray06">
                    {frames3.substring(
                        0,
                        currentFrameIndex - frames1.length - frames2.length,
                    )}
                </span>
            </div>
        </>
        // <div ref={ref} className="w-100" data-text={text}>
        //     {text.substring(
        //         0,
        //         Math.floor((totalLength * progressRate) / 100) + 1,
        //     )}{' '}
        //     &nbsp;
        // </div>
    );
};

export const Home = () => {
    return (
        <>
            <ContentsContainer>
                <div
                    id="home"
                    className="vhm-100 position-relative"
                    style={{ paddingTop: '80px' }}>
                    <div className="h-100 pt-xxl pt-md-0 d-flex flex-column w-100 align-items-center">
                        <div
                            id="home_signature"
                            className="row w-100 d-flex mb-xxl">
                            <Signature3D />
                        </div>

                        <div className="row w-100">
                            <div className="d-flex flex-column ps-0 ps-md-xxl ">
                                <div
                                    className="fw-bold text-gray07 lh-1375 mb-l"
                                    style={{ fontSize: '42px' }}>
                                    Hello, I'm Jieun!
                                </div>
                                <div className="lh-1500 text-gray06 fs-l">
                                    I'm a Product & UX designer based in San
                                    Francisco Bay Area, CA. <br />
                                    I enjoy crafting innovative design ideas to
                                    make better user experiences. <br />
                                    <br />
                                    Thanks for visiting my website!{' '}
                                    <span className="p-xxs">
                                        <EmojiSmile />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            id="home_warning"
                            className="row w-100 ps-0 ps-md-xxl flex-grow-1 align-items-end py-l">
                            <div className="row m-0 p-0">
                                <div className="col-auto">
                                    <Star />
                                </div>
                                <div className="col lh-1833 ps-0">
                                    My work is{' '}
                                    <span className="fw-bold highlight">
                                        password-protected.
                                    </span>
                                    <br />
                                    <span className="text-gray06">
                                        If you don't have a password, please
                                        reach out to me to continue exploring my
                                        portfolio!
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div
                        id = "home_warning"
                        className="w-100 position-absolute bottom-0 pb-l">

                    </div> */}
                </div>

                <PageEnd />
            </ContentsContainer>

            {/* <Projects /> */}
            <ContentsContainer>
                <Projects />

                <PageEnd className="pt-xxl" />
            </ContentsContainer>
        </>
    );
};

const Projects = () => {
    const ref = useRef(null);
    const [touched, touch] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            const triggerPosition = ref.current.parentNode.offsetTop;
            const scrollPosition = window.scrollY + window.innerHeight;

            if (scrollPosition > triggerPosition) {
                touch(true);
            } else {
                touch(false);
            }
        };
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        // For chrome, scroll snap works
        // Elsewhere, scrollIntoView() works.
        if (touched) {
            let html = document.querySelector('html');
            html.style['scrollSnapType'] = 'y mandatory';
            setTimeout(() => {
                html.style['scrollSnapType'] = 'none';
                ref.current.scrollIntoView({ behavior: 'smooth' });
            }, 110);
        }
    }, [touched]);

    return (
        <>
            <h2
                className="fw-bold position-relative"
                style={{ margin: '60px 0 60px 0' }}>
                <span
                    ref={ref}
                    id="projects"
                    className="position-absolute"
                    style={{
                        transform: 'translateY(-80px)',
                        scrollSnapAlign: `${touched ? 'start' : ''}`,
                    }}
                />
                Projects
            </h2>

            <div className="row gy-xxxl">
                <Project
                    hoverTitle="Alias Icon Modernization"
                    src={alias}
                    href="/projects/alias-icon-modernization"
                    tag="UI/Visual Design"
                    title="Alias Icon Modernization">
                    Alias is updating the iconography to improve clarity, by
                    removing all unnecessary visual details, noise and
                    inconsistency that hinders readability.
                </Project>

                <Project
                    hoverTitle="Alias New Onboarding Screen"
                    src={onboarding}
                    href="/projects/alias-new-onboarding-screen"
                    tag="Product design"
                    title="Alias New Onboarding Screen">
                    Alias wants to design the new onboarding screen to enhance
                    user experience by providing easy access to learning
                    materials and aligning the interface with other Autodesk
                    products.
                </Project>

                <Project
                    hoverTitle={
                        <span>Panel for Multi-brand Design system</span>
                    }
                    src={panel}
                    href="/projects/panel-component-design-system"
                    tag="Design System, UX Design, Product Design"
                    title={<span>Panel for Multi-brand Design system</span>}>
                    Designing 'panel' component for multi-brand design system,
                    from initiating design concepts to execution of the
                    component.
                </Project>

                <Project
                    hoverTitle={
                        <span>
                            AutoCAD Tab Redesign, Extended to Multi-brand Design
                            System
                        </span>
                    }
                    src={tab}
                    href="/projects/autocad-tab-design-system"
                    tag="Design System, UX Design, Product Design"
                    title={
                        <span>
                            AutoCAD Tab Redesign, <br />
                            Extended to Multi-brand Design System
                        </span>
                    }>
                    Redesign 'tab' component for AutoCAD to align with the
                    design system.
                </Project>
            </div>

            <PageEnd />
        </>
    );
};
