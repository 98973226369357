import { useLayoutEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { drawSignature } from './Animations';
import { AutodeskLogo, AnimatedSignature, Safe, Signature } from './Icons';
import { ReactComponent as Close } from './img/svg/Close.svg';
import { ReactComponent as Download } from './img/svg/Download.svg';
import { ReactComponent as Email } from './img/svg/Email.svg';
import { ReactComponent as Hamburger } from './img/svg/Hamburger.svg';
import { ReactComponent as LinkedIn } from './img/svg/LinkedIn.svg';
import { ReactComponent as RightArrow } from './img/svg/RightArrow.svg';
import { ContentsContainer, PageEnd } from './Layout';
import { useModalContext } from './Modal';
import { checkPasscode } from './Security';

const Tab = ({ href = '', children }) => (
    <div className="col-auto">
        <HashLink className="text-decoration-none" smooth to={href}>
            <div className="fw-bold position-relative highlight-on-hover">
                <div className="h-100 position-absolute start-0 top-50 translate-middle-y" />
                <span className="m-m">{children}</span>
            </div>
        </HashLink>
    </div>
);

export const Topbar = () => {
    const modalContext = useModalContext();
    useLayoutEffect(() => {
        drawSignature();
    }, []);
    return (
        <div className="position-fixed w-100" style={{ zIndex: '1040' }}>
            <div className="backdrop-blur">
                <div
                    className="d-flex align-items-center justify-content-between px-m py-xl"
                    style={{ height: '80px' }}>
                    <HashLink smooth to="/#home" className="position-relative">
                        <AnimatedSignature />
                    </HashLink>
                    <div className="row gx-xl d-none d-md-flex align-items-center">
                        <Tab href="/#home">Home</Tab>
                        <Tab href="/#projects">Projects</Tab>
                        <Tab href="/about">About</Tab>
                        {/* <Tab title="RESUME" href="/resume">
                            <span className="me-xxs">Resume</span>
                            <Download />
                        </Tab> */}
                        <div className="col-auto">
                            <a
                                className="text-decoration-none"
                                href="/jieunjaykim-resume.pdf">
                                <div className="fw-bold position-relative highlight-on-hover">
                                    <div className="h-100 position-absolute start-0 top-50 translate-middle-y" />
                                    <span className="m-m">
                                        <span className="me-xxs">Resume</span>
                                        <Download />
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div
                        className="d-flex d-md-none"
                        role="button"
                        onClick={() => modalContext.on('navigation')}>
                        <Hamburger />
                    </div>
                </div>
            </div>
        </div>
    );
};

const HorizontalDivider = ({ className = '' }) => (
    <div className={`border-top border-bottom-0 border-gray02 ${className}`} />
);

export const Footer = () => (
    <>
        <HorizontalDivider className="w-75" />
        <div className="py-xl">
            <ContentsContainer
                className="w-100 bg-opacity-10"
                style={{ height: '20vh' }}>
                <div style={{ marginBottom: '-20px' }}>
                    <Signature />
                </div>
                <div className="fw-bold text-gray06">
                    Sr. Product/UX Designer
                </div>
                <div className="row d-flex align-items-center py-l">
                    <div className="col-auto d-flex justify-content-center">
                        <a href="mailto:jieunjay.kim@gmail.com">
                            <div className="footer-icon d-flex align-items-end">
                                <Email />
                            </div>
                        </a>
                    </div>
                    <div className="col-auto d-flex align-items-end justify-content-center">
                        <a href="https://www.linkedin.com/in/jieun-jay-kim-79710b51/">
                            <div className="footer-icon d-flex align-items-end">
                                <LinkedIn />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="fw-bold h4">Let's Connect</div>
                <div className="fw-bold text-gray06">
                    Feel free to reach out, and thank you for stopping by!
                </div>
            </ContentsContainer>
            <PageEnd />
        </div>
    </>
);

export const Tag = ({ text = '' }) => (
    <div className="col-auto">
        <div className="tag rounded-pill fw-bold fs-s">{text}</div>
    </div>
);

const ProjectThumbnail = ({ hoverTitle, src, href }) => {
    const navigate = useNavigate();
    return (
        <div role="button" onClick={() => navigate(href)}>
            <figure className="position-relative mb-0 scale-up-on-hover rounded-2">
                <div
                    className="position-absolute w-100 h-100 d-flex align-items-center
                justify-content-center text-white">
                    {/* <div className="position-absolute top-0 start-0 m-xl">
                    <AutodeskLogo color="white"/>
                    </div>
                    <h2 className="text-center">
                        {hoverTitle}
                    </h2> */}
                </div>
                <img
                    className="mw-100 mh-100 rounded-2"
                    src={src}
                    alt={hoverTitle}
                />
            </figure>
        </div>
    );
};

const ProjectIntro = ({ children, tag, title, href }) => {
    const navigate = useNavigate();
    return (
        <div className="w-100">
            <div className="flex-row mb-l">
                <AutodeskLogo />
            </div>
            <div className="flex-row mb-xxs">
                <span className="fs-s opacity-50">{tag}</span>
            </div>
            <div className="flex-row mb-m">
                <span className="fs-l fw-bold">{title}</span>
            </div>
            <div className="flex-row mb-m">
                <span className="text-gray06" style={{ lineHeight: '1.467' }}>
                    {children}
                </span>
            </div>
            <div className="row d-flex justify-content-center justify-content-sm-start">
                <div className="col-12 col-sm-auto">
                    <div
                        className="btn btn-outline-gray09 view-project fs-s fw-bold lh-2000 py-xs px-l
                    rounded-2 d-flex align-items-center justify-content-center"
                        onClick={() => navigate(href)}>
                        <span className="me-s ls-700">Enter password</span>{' '}
                        <RightArrow />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Project = ({
    hoverTitle = '',
    src,
    href = '',
    tag = '',
    title = '',
    children,
}) => (
    <div className="col-12">
        <div className="row gx-xl">
            <div className="col-12 col-lg-7 d-flex align-items-center">
                <ProjectThumbnail
                    hoverTitle={hoverTitle}
                    src={src}
                    href={href}
                />
            </div>
            <div className="col-12 col-lg-5 pt-m d-flex align-items-start">
                <ProjectIntro tag={tag} title={title} href={href}>
                    {children}
                </ProjectIntro>
            </div>
        </div>
    </div>
);

export const ProjectBanner = ({ src, alt, title = '' }) => (
    <div className="col-12" style={{ paddingTop: '80px' }}>
        <img className="mw-100 mh-100" src={src} alt={alt} />
        <h2 className="fw-bold mt-l mb-0">{title}</h2>
    </div>
);

// Provide more consistent view to Pdf file across browser (especially Safari-compatible)
// compared to <a href=... >
export const PreviewPdf = ({ href }) => (
    <div
        className="vhm-100 overflow-hidden d-flex flex-column"
        style={{ background: 'rgb(82, 86, 89)' }}>
        <div className="flex-grow-1" />
        <div className="w-100 h-100 px-xxs px-sm-0">
            <embed src={href} className="w-100 h-100" type="application/pdf" />
        </div>
    </div>
);

const NavLink = ({ href = '', text = '' }) => {
    const modalContext = useModalContext();
    return (
        <HashLink
            className="text-decoration-none col-12 py-l text-white fs-l"
            smooth
            to={href}
            onClick={() => modalContext.off()}>
            {text}
        </HashLink>
    );
};

export const Navigation = () => {
    const modalContext = useModalContext();
    return (
        <div className="modal-dialog w-100 mw-100 h-100">
            <div
                className="modal-content rounded-0 h-100"
                style={{ background: '#1F1F1F' }}>
                <div className="modal-header px-xl py-l text-white">
                    <Signature />
                    <div className="ms-auto">
                        <div
                            onClick={() => {
                                modalContext.off();
                            }}
                            role="button">
                            <Close />
                        </div>
                    </div>
                </div>

                <div className="row text-center py-xxl">
                    <NavLink href="/#home" text="Home" />
                    <NavLink href="/#projects" text="Projects" />
                    <NavLink href="/about" text="About" />
                    {/* <NavLink href="/resume" text="Resume" /> */}

                    <a
                        className="text-decoration-none col-12 py-l text-white fs-l"
                        href="/jieunjaykim-resume.pdf"
                        onClick={() => modalContext.off()}>
                        Resume
                    </a>
                </div>
            </div>
        </div>
    );
};

export const CheckPasscode = ({ validate }) => {
    const navigate = useNavigate();
    const modalContext = useModalContext();
    const handleSubmit = (e) => {
        e.preventDefault();
        checkPasscode(e.currentTarget).then((result) => {
            if (result) {
                validate(true);
            } else {
                window.location.reload();
            }
        });
    };

    return (
        <div className="modal-dialog w-100">
            <div className="modal-content">
                <div className="modal-header px-xl py-l">
                    <div className="ms-auto">
                        <div
                            onClick={() => {
                                modalContext.off();
                                navigate(-1);
                            }}
                            role="button">
                            <Close />
                        </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="d-flex flex-column px-l px-sm-xxxl mb-xxl">
                        <div className="d-flex justify-content-center mb-l">
                            <Safe />
                        </div>
                        <div className="d-flex flex-column justify-content-center fs-xl lh-1375 mb-l">
                            <div className="d-flex justify-content-center">
                                <div
                                    className="text-center"
                                    style={{ maxWidth: '250px' }}>
                                    <span className="text-gray06 fw-bold">
                                        Please enter
                                    </span>{' '}
                                    <span className="fw-bold">password</span>{' '}
                                    <span className="text-gray06 fw-bold">
                                        to view my portfolio
                                    </span>
                                </div>
                            </div>
                        </div>

                        <input
                            type="text"
                            name="passcode"
                            className="form-control checkpassword rounded-2 px-l mb-l"
                            placeholder="Enter password"
                            style={{ height: '56px' }}
                        />

                        <button
                            type="submit"
                            className="submit btn btn-gray06 rounded-2 text-white fw-bold ls-1440 mb-l"
                            style={{ height: '56px' }}>
                            Submit
                        </button>

                        <span className="text-gray06 lh-1600">
                            Don't have password?
                        </span>
                        <div>
                            <span className="text-gray06 lh-1600">
                                Please contact me at
                            </span>
                            &nbsp;
                            <a
                                className="fw-bold"
                                href="mailto:jieunjay.kim@gmail.com">
                                jieunjay.kim@gmail.com
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
