import { Tag, ProjectBanner } from './Components';
import { Carousel, LaptopFrame, NoFrame } from './Frames';
import Audit from './img/alias/audit.png';
import Banner from './img/alias/banner.png';
import CurrentStatusDark from './img/alias/currentdark.png';
import CurrentStatusLight from './img/alias/currentlight.png';
import DesignExploration1 from './img/alias/designexploration1.png';
import DesignExploration21 from './img/alias/designexploration21.png';
import DesignExploration22 from './img/alias/designexploration22.png';
import DesignExploration23 from './img/alias/designexploration23.png';
import FinalDark from './img/alias/finaldark.png';
import FinalLight from './img/alias/finallight.png';
import Iconset01 from './img/alias/iconset01.png';
import Iconset02 from './img/alias/iconset02.png';
import Iconset03 from './img/alias/iconset03.png';
import Iconset04 from './img/alias/iconset04.png';
import Iconset05 from './img/alias/iconset05.png';
import Iconset06 from './img/alias/iconset06.png';
import Iconset07 from './img/alias/iconset07.png';
import Iconset08 from './img/alias/iconset08.png';
import InconsistentCameraView from './img/alias/inconsistentcameraview.png';
import InconsistentSymbol from './img/alias/inconsistentsymbol.png';
import InconsistentUseOfColor from './img/alias/inconsistentuseofcolor.png';
import Legacy from './img/alias/legacy.png';
import Process from './img/alias/process.png';
import Readability from './img/alias/readability.png';
import UniversalMetaphors from './img/alias/universalmetaphor.png';
import { ReactComponent as Company } from './img/svg/Company.svg';
import { ReactComponent as Contribute } from './img/svg/Contribute.svg';
import { ReactComponent as IconLibrary } from './img/svg/IconLibrary.svg';
import { ReactComponent as Styleguide } from './img/svg/Styleguide.svg';
import { ReactComponent as Users } from './img/svg/Users.svg';
import {
    Card,
    CardTitle,
    Column4,
    Column4Sm,
    Column6,
    Container,
    Icon,
    IconCaption,
    LineBreakMd,
    PageEnd,
    Paragraph,
    Row,
    RowContent,
    SmallCard,
    Subtitle,
} from './Layout';

export const Alias = () => (
    <>
        <Container className="pb-s">
            <ProjectBanner
                src={Banner}
                alt="Alias"
                title="Alias Icon Modernization"
            />
        </Container>

        <Container className="pt-s pb-s">
            <div className="row g-xs">
                <Tag text="Iconography" />
                <Tag text="Visual Design" />
                <Tag text="User Research" />
            </div>
        </Container>

        <Container>
            <RowContent title="Overview">
                <Row>
                    <div className="col">
                        <div className="py-m">
                            Autodesk Alias is a design software mainly used for
                            automotive 3D modeling. In Alias 2024, we have a
                            major update for complete UI change with new
                            iconography. In my team, we are responsible for new
                            user interface which brings cohesive experience and
                            visuals with other Autodesk product yet it still has
                            Alias DNA.
                        </div>
                    </div>

                    <div className="col-12 col-sm-auto">
                        <div>
                            <SmallCard title="Duration">
                                Project initiation: November 2021 <br />
                                Final implementation: Apr 2023
                            </SmallCard>
                        </div>
                    </div>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="My Role & Responsibilities">
                <Paragraph>
                    My mission was to enhance visual clarity across product
                    interface, improving user comprehension and efficiency by
                    removing all unnecessary visual details, noise and
                    inconsistency that hinders readability. Refreshing Alias
                    icons will also increase cohesion and consistency within
                    Alias, and other Autodesk products.
                </Paragraph>
                <Row>
                    <Column4Sm>
                        <Icon>
                            <IconLibrary />
                            <IconCaption>Official icon library</IconCaption>
                        </Icon>
                    </Column4Sm>
                    <Column4Sm>
                        <Icon>
                            <Styleguide />
                            <IconCaption>
                                Create style guide/ <br /> entire icon sets
                            </IconCaption>
                        </Icon>
                    </Column4Sm>
                    <Column4Sm>
                        <Icon caption="Official icon library">
                            <Contribute />
                            <IconCaption>
                                Contribute to
                                <br /> the Design System
                            </IconCaption>
                        </Icon>
                    </Column4Sm>
                </Row>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Challenges">
                <Paragraph>
                    <ul>
                        <li>No design guideline exists.</li>
                        <li>
                            Huge gap of styles in between other Autodesk icon
                            design and current Alias icon design.
                        </li>
                        <li>
                            Users have been used old icons for decades of
                            period, and afraid of changing.
                        </li>
                        <li>
                            Technical limitation: Certain features are not
                            available.
                        </li>
                    </ul>
                </Paragraph>
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Design Process">
                <NoFrame src={Process} />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Current Status">
                <LaptopFrame
                    src={CurrentStatusLight}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
                <LaptopFrame
                    src={CurrentStatusDark}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Audit/Research">
                <Row>
                    <Column6 className="py-s">
                        <ul>
                            <li className="pb-l">
                                I compiled a list of all icons currently in use,
                                then started to evaluate the visual consistency
                                of the icons in terms of style, size, color, and
                                level of detail.
                            </li>
                            <li className="pb-l">
                                I checked for accessibility including contrast
                                ratio between icons and user interface to ensure
                                that icons are clearly perceived.
                            </li>
                            <li className="pb-l">
                                A lot of icons are too complex and looked
                                similar, confusing users to identify its
                                function.
                            </li>
                            <li className="pb-l">
                                Some of general icons such as save, open, or
                                copy were hard to recognize. They can be
                                replaced by universal metaphors.
                            </li>
                            <li className="pb-l">
                                I had a review with internal users and customers
                                to identify any issues from users' point of
                                view.
                            </li>
                        </ul>
                    </Column6>
                    <Column6>
                        <NoFrame src={Audit} />
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Problems">
                <Row>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={Readability} />
                            </CardTitle>
                            <CardTitle>
                                Readability on
                                <LineBreakMd /> Light and Dark Background
                            </CardTitle>

                            <ul>
                                <li>
                                    Icons designed for light themes often
                                    appeared washed out or invisible on dark
                                    themes.
                                </li>
                                <li>
                                    Lack of contrast adjustments caused icons to
                                    blend into the background, reducing
                                    visibility.
                                </li>
                                <li>
                                    Icons used the same color palette for both
                                    themes, which was not optimized for
                                    readability across different backgrounds.
                                </li>
                            </ul>
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={InconsistentCameraView} />
                            </CardTitle>
                            <CardTitle>Inconsistent Camera Views</CardTitle>

                            <ul>
                                <li>
                                    Icons were created from varying camera
                                    angles, leading to a lack of uniformity.
                                </li>
                                <li>
                                    Some icons featured top-down views, while
                                    others had oblique or side views, creating
                                    visual dissonance.
                                </li>
                                <li>
                                    Users found it challenging to quickly
                                    recognize and differentiate between icons
                                    due to inconsistent perspectives.
                                </li>
                            </ul>
                        </Card>
                    </Column6>

                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={InconsistentSymbol} />
                            </CardTitle>
                            <CardTitle>
                                Inconsistent Use of Color Identity
                            </CardTitle>

                            <ul>
                                <li>
                                    Icons utilized a wide range of colors
                                    without a clear, standardized palette.
                                </li>
                                <li>
                                    Similar functions were represented by
                                    different colors, creating confusion.
                                </li>
                                <li>
                                    Users struggled to associate specific colors
                                    with specific actions or categories, leading
                                    to slower navigation.
                                </li>
                                <li>
                                    Historical accumulation of icons led to a
                                    patchwork of color schemes.
                                </li>
                            </ul>
                        </Card>
                    </Column6>

                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={InconsistentUseOfColor} />
                            </CardTitle>
                            <CardTitle>
                                Inconsistent Symbols and Badges
                            </CardTitle>

                            <ul>
                                <li>
                                    Lack of standardization in badge design led
                                    to confusion and misinterpretation.
                                </li>
                                <li>
                                    Users had difficulty understanding the
                                    meaning of certain icons due to varied
                                    symbols and badges.
                                </li>
                                <li>
                                    Visual clutter from diverse badges and
                                    symbols detracted from the overall interface
                                    coherence.
                                </li>
                            </ul>
                        </Card>
                    </Column6>

                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={Legacy} />
                            </CardTitle>
                            <CardTitle>
                                Differentiating between
                                <LineBreakMd />
                                Legacy and New Tools
                            </CardTitle>
                            <ul>
                                <li>
                                    Both legacy and new tools had their own
                                    icons, causing confusion among users.
                                </li>
                                <li>
                                    Legacy tools were often kept due to user
                                    preference, resulting in duplicate
                                    functionality and icons.
                                </li>
                                <li>
                                    The presence of both legacy and new icons
                                    cluttered the interface, making it less
                                    intuitive.
                                </li>
                                <li>
                                    Lack of clear visual differentiation
                                    strategies for legacy and new tools.
                                </li>
                            </ul>
                        </Card>
                    </Column6>

                    <Column6>
                        <Card>
                            <CardTitle>
                                <NoFrame src={UniversalMetaphors} />
                            </CardTitle>
                            <CardTitle>Universal Metaphors</CardTitle>

                            <ul>
                                <li>
                                    They help in creating intuitive and easily
                                    recognizable icons, reducing the learning
                                    curve for new users.
                                </li>
                                <li>
                                    Using universal metaphors ensures
                                    consistency across different platforms and
                                    products, enhancing usability.
                                </li>
                            </ul>
                        </Card>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Design Approach">
                <Paragraph>
                    <ol>
                        <li>
                            Balancing adherence to Weave(Autodesk Design System)
                            and current customer's familiarity
                        </li>
                        <li>
                            Compatibility with current UI and iconography to
                            slowly introduce visual change
                        </li>
                    </ol>
                </Paragraph>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Design Explorations 01">
                <Paragraph>
                    <Subtitle
                        className="mb-l"
                        title="First attempt to stay close to Weave guideline(other Autodesk product icons)"
                    />
                    Reactions
                    <ul>
                        <li>
                            Alias has many similar icons and hard to
                            differentiate icons
                        </li>
                        <li>Icons look too flat</li>
                        <li>Shading looks not realistic</li>
                    </ul>
                </Paragraph>
                <NoFrame src={DesignExploration1} />
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Design Explorations 02">
                <Paragraph>
                    <Subtitle
                        className="mb-l"
                        title="Second attempt to align more with current Alias icons, but
                    we adapted Weave color palette and flat icons (mix&match)"
                    />
                    What we modified from the first attempt:
                    <ul>
                        <li>
                            Explore more on the perspective view, making icons
                            more realistic and not too flat
                        </li>
                        <li>
                            Since there is a technical limitation that we can
                            only implement one icon set for both light and dark
                            theme, find the best contrast ratio that works for
                            both color themes.
                        </li>
                    </ul>
                    <br /> <br />
                    Reactions:
                    <ul>
                        <li>
                            I like the icons look simple and cleaner, but still
                            recognizable
                        </li>
                        <li>Gray line looks like it is disabled</li>
                        <li>
                            I prefer the blue tint for default object icon, gray
                            tinted objects look too muddy
                        </li>
                    </ul>
                </Paragraph>
            </RowContent>
            <NoFrame src={DesignExploration21} />
            <NoFrame src={DesignExploration22} />
            <NoFrame src={DesignExploration23} />
        </Container>

        {/* <Row title="Final Icon Set">
            <div className="px-s px-md-0 mt-l">
                <HorizontallyScrollableFrame src={AliasLongLight} />
            </div>
            <div className="px-s px-md-0 mt-l">
                <HorizontallyScrollableFrame src={AliasLongDark} />
            </div>
        </Row> */}

        <Container>
            <RowContent title="Final Icon Set">
                <Carousel
                    srcs={[
                        Iconset01,
                        Iconset02,
                        Iconset03,
                        Iconset04,
                        Iconset05,
                        Iconset06,
                        Iconset07,
                        Iconset08,
                    ]}
                />
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Final Design">
                <LaptopFrame
                    src={FinalLight}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />

                <LaptopFrame
                    src={FinalDark}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Outcome">
                <Row>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Users />
                            </CardTitle>
                            <CardTitle>User's Perspective</CardTitle>

                            <ul>
                                <li>
                                    Both dark and light themes are now
                                    supported, improving usability and
                                    readability across different display
                                    settings.
                                </li>
                                <li>
                                    Consistent iconography reduces the learning
                                    curve for new users and increases
                                    productivity for existing users.
                                </li>
                            </ul>
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Company />
                            </CardTitle>
                            <CardTitle>Company's Perspective</CardTitle>

                            <ul>
                                <li>
                                    The redesigned icons now align with the
                                    Autodesk design system, ensuring consistency
                                    across products and enhancing brand
                                    identity.
                                </li>
                                <li>
                                    Achieved silver medal status within the
                                    governance model
                                </li>
                            </ul>
                        </Card>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Reflection">
                <Paragraph>
                    From this project, I leaned many valuable lessons. <br />
                    <br />
                    Alias had pretty complex icon guideline. It was a challenge
                    to keep the balance between the Autodesk icon guideline and
                    Alias' own design direction. Due to its unique features,
                    users needs were pretty different from the design direction
                    of Autodesk design system. <br />
                    <br />
                    I also had several feedback sessions with end users, allowed
                    me to design with user-centric focus. It demonstrated the
                    significance of understanding user preferences, behaviors,
                    and expectations to create designs that resonate with the
                    target audience. <br />
                    <br />
                    Communication skills were also something that I have
                    improved from this project. Learning how to interpret
                    feedback, extracting meaningful insights, and communicate
                    design decisions demonstrates professional maturity and
                    enhances collaboration skills. <br />
                    <br />
                    In the end, I learned so much about the importance of user
                    feedback, flexibility to adapt different needs, and the
                    value of maintaining a user-centric design approach.
                </Paragraph>
            </RowContent>
        </Container>

        <PageEnd />
    </>
);
