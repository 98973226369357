import { Tag, ProjectBanner } from './Components';
import { NoFrame } from './Frames';
import Audit1 from './img/panel/audit1.png';
import Audit2 from './img/panel/audit2.png';
import Banner from './img/panel/banner.png';
import Exploration1 from './img/panel/exploration01.png';
import Exploration2 from './img/panel/exploration02.png';
import Exploration3 from './img/panel/exploration03.png';
import Exploration4 from './img/panel/exploration04.png';
import Exploration5 from './img/panel/exploration05.png';
import FinalDeisgn from './img/panel/finaldesign.png';
import Journey from './img/panel/journey.png';
import Persona from './img/panel/persona.png';
import Problem from './img/panel/problem.png';
import Process from './img/panel/process.png';
import Result from './img/panel/result.png';
import { ReactComponent as Accessibility } from './img/svg/Accessibility.svg';
import { ReactComponent as Audit } from './img/svg/Audit.svg';
import { ReactComponent as Behaviors } from './img/svg/Behaviors.svg';
import { ReactComponent as ComponentCreation } from './img/svg/ComponentCreation.svg';
import { ReactComponent as UserResearch } from './img/svg/UserResearch.svg';
import { ReactComponent as VariousContents } from './img/svg/VariousContents.svg';
import { ReactComponent as VisualClarity } from './img/svg/VisualClarity.svg';
import {
    Card,
    CardTitle,
    Column4,
    Column4Sm,
    Column6,
    Container,
    Icon,
    IconCaption,
    LineBreakMd,
    PageEnd,
    Paragraph,
    Row,
    RowContent,
    SmallCard,
} from './Layout';
import PanelVideo3 from './video/panel/behaviors.mov';
import PanelVideo1 from './video/panel/collapse.mp4';
import PanelVideo2 from './video/panel/expandcollapse.mp4';

export const Panel = () => (
    <>
        <Container className="pb-s">
            <ProjectBanner
                src={Banner}
                alt="Panel"
                title="Panel for Multi Brand Design System"
            />
        </Container>

        <Container className="pt-s pb-s">
            <div className="row g-xs">
                <Tag text="Design System" />
                <Tag text="User Research" />
                <Tag text="Visual Design" />
                <Tag text="Prototyping" />
            </div>
        </Container>

        <Container>
            <RowContent title="Overview">
                <Row>
                    <div className="col">
                        <div className="py-m">
                            Weave is the Autodesk product design system that
                            provides the tools that teams need to create
                            cohesive digital experiences. Weave is a multi-brand
                            design system which covers various use-cases from
                            complex, information-dense applications to simple,
                            minimalist applications. <br />
                            <br />
                            Each designer is fully responsible for every aspect
                            of one component that they are in charge of: user
                            research, visual design, interaction, layouts,
                            prototype, and documentation. Panel is one of the
                            most complex component due to its unique usages
                            across all Autodesk desktop products.
                        </div>
                    </div>

                    <div className="col-12 col-sm-auto">
                        <div>
                            <SmallCard title="Duration">
                                Project initiation: Nov 2018 <br />
                                Implementation: Feb 2019
                            </SmallCard>
                        </div>
                    </div>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="My Role & Responsibilities">
                <Paragraph>
                    My mission is to develop a comprehensive set of design,
                    content, interaction patterns, and re-usable components that
                    can be adaptable for all Autodesk products. Each component
                    must have clear accessibility guidelines, ensuring
                    consistency across products.
                    <br />
                    <br />I was responsible for creating multiple components,
                    but I've decided to highlight the “panel” component as the
                    centerpiece due to a few reasons:
                    <ul>
                        <li>
                            Complexity: The panel represents one of the most
                            complex design system components due to its
                            hierarchical structure and dynamic behavior.
                        </li>
                        <li>
                            Impact on User: Most of tools and controls at
                            Autodesk desktop product are contained in panels,
                            and it impacts the overall user experience directly.
                        </li>
                        <li>
                            Comprehensive skills: Panel design required a
                            combination of skills in user research, interaction
                            design, visual design, prototyping, testing, and
                            iteration.
                        </li>
                    </ul>
                </Paragraph>
                <Row>
                    <Column4Sm>
                        <Icon>
                            <Audit />
                            <IconCaption>Audit</IconCaption>
                        </Icon>
                    </Column4Sm>
                    <Column4Sm>
                        <Icon>
                            <UserResearch />
                            <IconCaption>User Research</IconCaption>
                        </Icon>
                    </Column4Sm>
                    <Column4Sm>
                        <Icon>
                            <ComponentCreation />
                            <IconCaption>Component Creation</IconCaption>
                        </Icon>
                    </Column4Sm>
                </Row>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Challenges">
                <Paragraph>
                    <ul>
                        <li>
                            Complexity: As more exceptions and unique cases came
                            to consider, the component became complex and often
                            created confusion for consumers.
                        </li>
                        <li>
                            Consistency across products/platforms: Designing
                            components that work seamlessly across different
                            products were pretty challenging since each product
                            had their unique patterns with their own guidelines
                            with technical constraints, requiring careful
                            adaptation.
                        </li>
                        <li>
                            Inclusive design: I needed to consider users with
                            various needs and abilities including keyboard
                            navigation or screen compatibility. It was
                            challenging to create components that meet
                            accessibility standards for all products.
                        </li>
                        <li>
                            Adoption: Panel was one of essential components for
                            most of desktop products. Introducing new design can
                            be met with resistance from the product team. I
                            needed to provide clear resources and support to
                            help them understand the benefits of adopting new
                            design system.
                        </li>
                    </ul>
                </Paragraph>
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Problems">
                <Row>
                    <Column4>
                        <Card>
                            <CardTitle>
                                Inconsistent
                                <LineBreakMd />
                                User Experience
                            </CardTitle>
                            Panels with varying designs and interactions across
                            products create a fragmented experience for users
                            who work across multiple tools within the Autodesk
                            ecosystem.
                        </Card>
                    </Column4>
                    <Column4>
                        <Card>
                            <CardTitle>
                                Inconsistency in
                                <LineBreakMd />
                                Design Language
                            </CardTitle>
                            The inconsistency in design undermines user trust
                            and loyalty. A unified design system strengthens
                            brand recognition and reinforces confidence in the
                            product suite
                        </Card>
                    </Column4>
                    <Column4>
                        <Card>
                            <CardTitle>Challenges in Maintenance</CardTitle>
                            Maintaining multiple panel designs across products
                            increases complexity for design and development
                            teams.
                        </Card>
                    </Column4>
                </Row>
                <NoFrame src={Problem} />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Design Process">
                <Paragraph>
                    The design process began with extensive research and
                    analysis. This included internal audits, understanding user
                    needs, and defining key features. <br />
                    <br />
                    In order to gain comprehensive insights about the panel
                    component, I facilitated a collaborative sessions with
                    designers from both product teams and design system team.
                    Through in-person meetings, virtual workshops through Mural,
                    or Zoom calls, I identified current status, existing
                    challenges, and their expectations for the redesigned panel
                    interface.
                    <br />
                    <br />
                    This process is to identify:
                    <ul>
                        <li>Deep understanding of users</li>
                        <li>Identify users pain points</li>
                        <li>Define common design pattern</li>
                        <li>Opportunities for improvement</li>
                    </ul>
                </Paragraph>
                <NoFrame src={Process} />
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Audit">
                <NoFrame src={Audit1} />
                <div className="py-xl" />
                <NoFrame src={Audit2} />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="User Persona">
                <Row>
                    <div className="col-6 col-md-4">
                        <div className="d-flex align-items-center justify-content-center py-l">
                            <NoFrame src={Persona} />
                        </div>
                    </div>
                    <div className="col-6 col-md-8">
                        <div className="py-l">
                            <h3 className="mb-xl fw-bold">Meet Margaret!</h3>
                            We've segmented our audience and chosen to
                            prioritize a single persona. <br />
                            She represents:
                            <ul>
                                <li>Majority of our audience</li>
                                <li>
                                    High influence on our design system by
                                    constribution
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Column6>
                        <div className="row gy-xl">
                            <div>
                                <h3 className="mb-xl fw-bold">Margaret Chen</h3>
                                <ul>
                                    <li>Age: 45 years old</li>
                                    <li>
                                        Occupation: Principle UX Designer at
                                        Revit
                                    </li>
                                    <li>Location: Portland, Oregon</li>
                                    <li>
                                        Experience: 20 years in design, 12 years
                                        at Autodesk
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="fw-bold">Responsibilities</h4>
                                <ul>
                                    <li>
                                        Overseeing the design of new features
                                        and enhancement for Revit
                                    </li>
                                    <li>
                                        Collaborating closely with architects,
                                        engineers
                                    </li>
                                    <li>
                                        Ensuring the design consistency across
                                        Revit and integration with the Autodesk
                                        design system
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="fw-bold">Design Tools</h4>
                                <ul>
                                    <li>Sketch app</li>
                                    <li>Adobe Photoshop</li>
                                    <li>Figma (beginner)</li>
                                </ul>
                            </div>
                        </div>
                    </Column6>

                    <Column6>
                        <div className="row gy-xl">
                            <div>
                                <h4 className="fw-bold">Goals</h4>
                                <ul>
                                    <li>
                                        To enhance usability and accessibility
                                        of Revit, making it easier for
                                        professionals to adopt
                                    </li>
                                    <li>
                                        To ensure that Revit's design aligns
                                        with Autodesk overall design system,
                                        promoting cohesive user experience
                                        across products
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="fw-bold">Challenges</h4>
                                <ul>
                                    <li>
                                        Navigating the complexity of integrating
                                        Revit's unique features and workflows
                                        with the design system
                                    </li>
                                    <li>
                                        Addressing the diverse needs of Revit's
                                        use base
                                    </li>
                                    <li>
                                        Managing and incorporating feedback from
                                        a wide range of users, often with
                                        conflicting requirements
                                    </li>
                                    <li>
                                        Keeping up with rapid technological
                                        advancements
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="fw-bold">
                                    Project Management/Collaboration Tools
                                </h4>
                                <ul>
                                    <li>JIRA</li>
                                    <li>Mural</li>
                                    <li>Slack</li>
                                </ul>
                            </div>
                        </div>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="User Journey">
                <NoFrame src={Journey} />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Users' Pain Points">
                <Row>
                    <Column4>
                        <Card title="Desire for More Examples">
                            Product teams have expressed a desire to see more
                            examples of panel designs with various contents to
                            use as guidance. The lack of visual examples or case
                            studies showcasing different panel implementations
                            creates uncertainty in adapting designs.
                        </Card>
                    </Column4>

                    <Column4>
                        <Card title="Inconsistency Across Brands">
                            Different products under the same umbrella may have
                            their own unique visual styles and guidelines,
                            leading to inconsistencies across brands. This
                            fragmentation can confuse users and dilute brand
                            identity.
                        </Card>
                    </Column4>

                    <Column4>
                        <Card title="Complexity">
                            Panels in the design system have too many optional
                            elements, overwhelming and confusing users. This
                            complexity makes it difficult to understand the
                            purpose of the panel and how to navigate its
                            features effectively.
                        </Card>
                    </Column4>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Key Features for Design Improvement">
                <Row>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <VisualClarity />
                            </CardTitle>
                            <CardTitle>Visual Clarity</CardTitle>
                            Panels commonly act as containers that enclose
                            specific contents. Some of clear visual dividers
                            such as shadows or borders may be needed to separate
                            panels from surround elements.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <VariousContents />
                            </CardTitle>
                            <CardTitle>Various Contents</CardTitle>
                            Panels obtains numerous types of contents such as
                            text, images, forms, tables, and controls. The
                            layouts within panels should consider clear
                            hierarchy of contents.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Accessibility />
                            </CardTitle>
                            <CardTitle>Accessibility</CardTitle>
                            Panels should be designed with accessibility,
                            ensuring that understandable for all users including
                            people with disabilities. This may involve color
                            contrast, keyboard navigation, or screen
                            compatibility.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Behaviors />
                            </CardTitle>
                            <CardTitle>Behaviors</CardTitle>
                            There were some of key behaviors to consider to
                            design panels for multi-products. Panels had many
                            interactive behaviors such as close/open,
                            expand/collapse, and docking/undocking.
                        </Card>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Design Exploration">
                <Row>
                    <NoFrame src={Exploration1} />
                    <NoFrame src={Exploration2} />
                    <NoFrame src={Exploration3} />
                    <NoFrame src={Exploration4} />
                    <NoFrame src={Exploration5} />
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Prototypes">
                <Row>
                    <Column6>
                        <video
                            className="w-100 mt-m"
                            controls
                            autoPlay={false}
                            src={`${PanelVideo1}#t=0.001`}
                            type="video/mp4"
                        />
                    </Column6>
                    <Column6>
                        <video
                            className="w-100 mt-m"
                            controls
                            autoPlay={false}
                            src={`${PanelVideo2}#t=0.001`}
                            type="video/mp4"
                        />
                    </Column6>
                    <div className="col-12">
                        <video
                            className="w-100 mt-m"
                            controls
                            autoPlay={false}
                            src={`${PanelVideo3}#t=0.001`}
                            type="video/mp4"
                        />
                    </div>
                </Row>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Final Design">
                <NoFrame src={FinalDeisgn} />
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Result">
                <NoFrame src={Result} />
                <Paragraph>
                    The design system team mainly monitor 19 products that we
                    call 'hero products' which make the most of revenue of
                    Autodesk. As a result,
                    <b>
                        16 out of 19 core products adopted the panel component.
                    </b>
                    <br /> <br />A standardized panel component fosters
                    collaboration among users who work across different Autodesk
                    products. Familiarity with the panel interface enables
                    smoother transition between products and promotes knowledge
                    sharing. By implementing a standardized panel, users across
                    different Autodesk products experience consistency in design
                    patterns and work flows.
                </Paragraph>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Reflection">
                Even we launched the desktop design system successfully, it
                still needs some additional functions and patterns as more
                products started to adopt the components. Building multi-brand
                design system was really challenging in terms of many aspects.
                Each component took much longer time because of its complexity
                and different expectations from different teams. We also
                accepted that the components needed to be flexible, so
                components can be adapted to each product's unique pattern by
                keeping atomic level of elements strict, but allowing minor
                modifications depending on the product team. I learned that the
                design system is not static; it keeps evolving over time to
                accommodate changing needs and requirements.
                <br /> <br />
                With all challenges, I had a pleasant time learning about
                strategic process including collaboration, user-testing,
                real-world usage-data, and iteration.
            </RowContent>
        </Container>

        <PageEnd />
    </>
);
