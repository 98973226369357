import { useLayoutEffect } from 'react';

import {
    AnimatedAvatar,
    Illustrations,
    Infographic,
    Pixels,
    Traveling,
} from './Icons';
import { ReactComponent as Bag } from './img/svg/Bag.svg';
import { ReactComponent as Computer } from './img/svg/Computer.svg';
import { ReactComponent as Hiking } from './img/svg/Hiking.svg';
import { ReactComponent as House } from './img/svg/House.svg';
import { ReactComponent as Joystick } from './img/svg/Joystick.svg';
import { ReactComponent as Plane } from './img/svg/Plane.svg';
import { ReactComponent as UserStar } from './img/svg/UserStar.svg';
import { Container, PageEnd } from './Layout';
import { PersonaNetworkGraph } from './Networkgraph';

const DescriptionTitle = ({ children }) => (
    <div className="pb-l fw-bold fs-xl">{children}</div>
);

const Description = ({ title = '', children }) => (
    <div className="fs-l">
        {title ? <DescriptionTitle>{title}</DescriptionTitle> : null}
        {children}
    </div>
);

const List = ({ icon, children }) => (
    <div className="d-flex align-items-start">
        <div className="d-flex align-items-center">{icon}</div>
        <div className="ms-m">{children}</div>
    </div>
);

export const About = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container>
            <div className="row g-xl gx-md-xxl mt-xxxl">
                <div className="col-12 col-md">
                    <h2 className="fw-bold lh-1600 mb-xxl">
                        Hello, <br />I am Jieun or call me Jay
                    </h2>
                    {/* <h5 className='text-gray06'>
                        I am a product, UX designer with 9+ years of experiences
                        based in San Francisco Bay Area, CA
                        <br /> <br />
                        During my past 9 years of career, I specialized in
                        product design and user interface design. I love
                        identifying, analyzing user experience problems, and
                        create data-driven design to help making something
                        essential to help people.
                    </h5> */}

                    <div className="row gy-xxl">
                        <Description>
                            <List icon={<Bag />}>
                                Product/UX designer with 9+ years of
                                experiences.
                            </List>
                            <List icon={<House />}>
                                Based in San Francisco Bay Area, CA
                            </List>
                        </Description>

                        <Description title="Specialized in...">
                            <List icon={<UserStar />}>
                                User-Centered Design
                            </List>
                            <List icon={<Joystick />}>
                                Crafting UI Components
                            </List>
                            <List icon={<Computer />}>Design system</List>
                        </Description>

                        <Description title="Outside of work...">
                            <List icon={<Plane />}>
                                Make plans for travel (I have 10 years of plan)
                            </List>
                            <List icon={<Hiking />}>Hiking</List>
                        </Description>
                    </div>
                </div>
                <div className="col-12 col-md-auto d-flex align-items-end justify-content-center">
                    <AnimatedAvatar />
                </div>
            </div>

            {/* <div className="row mt-xl gy-xl">
                <div className="col-12">
                    <Infographic />
                </div>

                <div className="col-12 fw-bold fs-xl">I love...</div>
                <div className="col-12 px-m px-md-xxxl">
                    <div
                        className="bg-black d-flex align-items-start justify-content-start p-xxl"
                        style={{ height: '260px' }}>
                        <Pixels />
                    </div>
                </div>
                <div
                    className="col-12 px-m px-md-xxxl"
                    style={{ height: '260px' }}>
                    <div
                        className="bg-black d-flex align-items-center justify-content-end p-xxl"
                        style={{ height: '260px' }}>
                        <Illustrations />
                    </div>
                </div>
                <div
                    className="col-12 px-m px-md-xxxl"
                    style={{ height: '260px' }}>
                    <div
                        className="bg-black d-flex align-items-end justify-content-start p-l"
                        style={{ height: '260px' }}>
                        <Traveling />
                    </div>
                </div>
            </div>

            <div className="row mt-xl gy-xl">
                <div className="col-12">
                    <PersonaNetworkGraph />
                </div>
            </div> */}

            <PageEnd />
        </Container>
    );
};
