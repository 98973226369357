import { ProjectBanner, Tag } from './Components';
import { Carousel, LaptopFrame, NoFrame } from './Frames';
import Banner from './img/onboarding/banner.png';
import Carousel11 from './img/onboarding/carousel1-0.onboardingscreen.png';
import Carousel12 from './img/onboarding/carousel1-1.Detail page.png';
import Carousel13 from './img/onboarding/carousel2-0.Within application (docked ver.).png';
import Carousel14 from './img/onboarding/carousel2-1.Within application - detail page (docked ver.).png';
import Carousel15 from './img/onboarding/carousel3-0.Within application(docked ver. minimum size).png';
import Carousel16 from './img/onboarding/carousel3-1.Within application - detail page(docked ver. minimum size).png';
import Carousel17 from './img/onboarding/carousel3-2.Within application - detail page(docked ver. minimum size).png';
import CurrentStatus from './img/onboarding/currentstatus.png';
import FinalDesign from './img/onboarding/finaldesign.png';
import NewUserFlow from './img/onboarding/newflow.png';
import UserFlow from './img/onboarding/userflow.png';
import { ReactComponent as Accessibility } from './img/svg/Accessibility2.svg';
import { ReactComponent as Autodesk } from './img/svg/Autodesk.svg';
import { ReactComponent as Collaboration } from './img/svg/Collaboration.svg';
import { ReactComponent as Enhancement } from './img/svg/Enhancement.svg';
import { ReactComponent as ProductDesign } from './img/svg/ProductDesign.svg';
import { ReactComponent as Userfriendly } from './img/svg/Userfriendly.svg';
import { ReactComponent as Wireframe } from './img/svg/Wireframe.svg';
import {
    Column6,
    Row,
    PageEnd,
    Card,
    CardTitle,
    Column4,
    Title,
    Container,
    RowContent,
    SmallCard,
    Paragraph,
    Icon,
    IconCaption,
    LineBreakMd,
    Column4Sm,
} from './Layout';
import KeyFeatureVideo2 from './video/onboarding/essentialSkills-localization.mp4';
import KeyFeatureVideo4 from './video/onboarding/flexible.mp4';
import KeyFeatureVideo1 from './video/onboarding/learning.mp4';
import KeyFeatureVideo3 from './video/onboarding/SearchFeature.mp4';

export const Onboarding = () => (
    <>
        <Container className="pb-s">
            <ProjectBanner
                src={Banner}
                alt="Onboarding"
                title="New Alias Onboarding Experience"
            />
        </Container>

        <Container className="pt-s pb-s">
            <div className="row g-xs">
                <Tag text="Product Design" />
                <Tag text="Visual Design" />
            </div>
        </Container>

        <Container>
            <RowContent title="Overview">
                <Row>
                    <div className="col">
                        <div className="py-m">
                            Alias, known for its stiff learning curve and being
                            hard to learn for first-time users, required a more
                            intuitive onboarding experience. While there is a
                            tutorial site available, many users struggled to
                            find and access it. The onboarding process was
                            redesigned to enhance user experience by providing
                            easy access to learning materials and aligning the
                            interface with other Autodesk products. This
                            redesign aims to support new users in their initial
                            interaction with the software, making the learning
                            curve less steep and improving overall user
                            satisfaction.
                        </div>
                    </div>

                    <div className="col-12 col-sm-auto">
                        <div>
                            <SmallCard title="Duration">
                                Project initiation: Dec 2022 <br />
                                Implementation: Feb 2023
                            </SmallCard>
                        </div>
                    </div>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Constraints">
                <Row>
                    <Column6>
                        <Card>
                            <CardTitle>Lack of Direct User Input</CardTitle>
                            <li className="pb-l">
                                No direct contact with users during the design
                                process.
                            </li>
                            <li className="pb-l">
                                Relied on general insights and feedback from
                                previous user research but lacked specific data
                                from current user flows.
                            </li>
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>Time Constraints</CardTitle>
                            <li className="pb-l">
                                Tight project timelines left insufficient time
                                for comprehensive user testing and iterative
                                feedback cycles.
                            </li>
                            <li className="pb-l">
                                Limited ability to validate designs with real
                                users before moving forward.
                            </li>
                        </Card>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="My Role & Responsibilities">
                <Paragraph>
                    As the product designer on this project, I was responsible
                    for creating a seamless and engaging onboarding experience
                    for new users of Alias. My role encompassed several key
                    tasks:
                    <br />
                    <br />
                    <ul>
                        <li>
                            Product Design: Designed and provided various
                            assets, including icons and images, to support the
                            new onboarding process and enhance visual appeal.
                        </li>
                        <li>
                            Wireframe: Developed detailed wireframes to map out
                            the new onboarding flow, ensuring that each step was
                            logical and user-friendly.
                        </li>
                        <li>
                            Collaboration: Worked closely with developers, UX
                            researchers, and other stakeholders to ensure the
                            new onboarding process was technically feasible and
                            aligned with user needs and business goals.
                        </li>
                    </ul>
                </Paragraph>
                <Row>
                    <Column4Sm>
                        <Icon>
                            <ProductDesign />
                            <IconCaption>Product Design</IconCaption>
                        </Icon>
                    </Column4Sm>
                    <Column4Sm>
                        <Icon>
                            <Wireframe />
                            <IconCaption>Wireframe</IconCaption>
                        </Icon>
                    </Column4Sm>
                    <Column4Sm>
                        <Icon>
                            <Collaboration />
                            <IconCaption>Collaboration</IconCaption>
                        </Icon>
                    </Column4Sm>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Current Status & User Flow">
                <LaptopFrame
                    src={CurrentStatus}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
                <NoFrame src={UserFlow} />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Problems">
                <Row>
                    <Column4>
                        <Card>
                            <CardTitle>Difficult Navigation</CardTitle>
                            New users found it challenging to locate and access
                            learning materials and tutorials.
                        </Card>
                    </Column4>
                    <Column4>
                        <Card>
                            <CardTitle>Inconsistent Design</CardTitle>
                            The onboarding screens of Alias differed
                            significantly from other Autodesk products, causing
                            confusion and a disjointed user experience.
                        </Card>
                    </Column4>
                    <Column4>
                        <Card>
                            <CardTitle>Lack of Guidance</CardTitle>
                            There was insufficient guidance for new users,
                            resulting in a steep learning curve and lower
                            engagement rates.
                        </Card>
                    </Column4>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="New User Flow">
                <NoFrame src={NewUserFlow} />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Objectives">
                <Row>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Accessibility />
                            </CardTitle>
                            <CardTitle>Improve Accessibility</CardTitle>
                            Ensure that new users can easily find and access
                            tutorials, documentation, and other learning
                            resources from the onboarding screens.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Userfriendly />
                            </CardTitle>
                            <CardTitle>User-Friendly Interface</CardTitle>
                            Simplify the onboarding process to reduce cognitive
                            load and make the initial steps straightforward and
                            engaging.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Enhancement />
                            </CardTitle>
                            <CardTitle>Enhanced User Engagement</CardTitle>
                            Increase user engagement and retention by providing
                            a smooth and supportive introduction to Alias.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>
                                <Autodesk />
                            </CardTitle>
                            <CardTitle>
                                Consistency Across Autodesk Products
                            </CardTitle>
                            Align the design of the onboarding screens with the
                            visual and functional standards of other Autodesk
                            products for a seamless user experience.
                        </Card>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Conceptualization">
                <Paragraph>
                    The conceptualization phase began with creating the
                    information architecture and low-fidelity concepts for the
                    primary use cases. This step involved: <br />
                    <br />
                    <li>
                        Information Architecture: Outlining the structure and
                        organization of content to ensure logical and intuitive
                        navigation.
                    </li>
                    <li>
                        Low-Fidelity Concepts: Developing initial sketches and
                        wireframes to visualize the onboarding flow and identify
                        key interactions.
                    </li>
                    <br />
                    After receiving approval from the UX manager and
                    stakeholders on these initial concepts, we proceeded to
                    build low-fidelity mockups. These mockups provided a more
                    detailed view of the design and allowed for further
                    iteration and refinement.
                </Paragraph>

                <Carousel
                    srcs={[
                        Carousel11,
                        Carousel12,
                        Carousel13,
                        Carousel14,
                        Carousel15,
                        Carousel16,
                        Carousel17,
                    ]}
                />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Key Features">
                <Row>
                    <Column6>
                        <video
                            className="w-100"
                            controls
                            autoPlay={false}
                            src={`${KeyFeatureVideo1}#t=0.001`}
                            type="video/mp4"
                        />
                    </Column6>
                    <Column6>
                        <Title title="Easy Access to the Tutorial Site" />
                        <Paragraph>
                            Users can now easily find and navigate to the
                            tutorial site directly from the onboarding screens,
                            reducing the time and effort required to locate
                            essential learning resources.
                        </Paragraph>
                    </Column6>
                    <Column6>
                        <video
                            className="w-100"
                            controls
                            autoPlay={false}
                            src={`${KeyFeatureVideo2}#t=0.001`}
                            type="video/mp4"
                        />
                    </Column6>
                    <Column6>
                        <Title title="Easy Access to Essential Skills with Localization" />
                        <Paragraph>
                            The new onboarding process includes links to
                            essential skills tutorials, with localized content
                            to cater to users from different countries,
                            enhancing the learning experience for a global
                            audience.
                        </Paragraph>
                    </Column6>
                    <Column6>
                        <video
                            className="w-100"
                            controls
                            autoPlay={false}
                            src={`${KeyFeatureVideo3}#t=0.001`}
                            type="video/mp4"
                        />
                    </Column6>
                    <Column6>
                        <Title title="Enhanced Search Feature" />
                        <Paragraph>
                            A search feature was integrated into the onboarding
                            screens, allowing users to quickly find specific
                            tutorials, documentation, and other resources.
                        </Paragraph>
                    </Column6>
                    <Column6>
                        <video
                            className="w-100"
                            controls
                            autoPlay={false}
                            src={`${KeyFeatureVideo4}#t=0.001`}
                            type="video/mp4"
                        />
                    </Column6>
                    <Column6>
                        <Title title="Flexible (Responsive) Screens" />
                        <Paragraph>
                            The onboarding screens were designed to be
                            responsive. Users can access to the onboarding
                            screen while users work within the workspace. The
                            new onboarding screen takes less screen space,
                            allowing users to continue their work without
                            significant disruption.
                        </Paragraph>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Final Design">
                <LaptopFrame
                    src={FinalDesign}
                    id="alias-final-1"
                    x={109}
                    y={38}
                />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Outcome">
                <Row>
                    <Column6>
                        <Card>
                            <CardTitle>Enhanced internal alignment</CardTitle>
                            <li>
                                The redesigned onboarding screens are aligned
                                with Autodesk’s broader design system (Weave),
                                achieving silver status in design system
                                governance.
                            </li>
                            <li>
                                It helped first-time users transition smoothly
                                across tools, reducing the learning curve and
                                improving onboarding efficiency.
                            </li>
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>Qualitative Feedback</CardTitle>
                            <li>
                                The new onboarding design was presented at
                                Autodesk’s user Forum, where it received
                                enthusiastic feedback from end-users
                            </li>
                            <li>
                                Early feedback highlighted improvements in
                                usability, clarity, and ease of navigation.
                            </li>
                        </Card>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Future Improvement">
                <Row>
                    <Column6>
                        <Card>
                            <CardTitle>Personalized Onboarding</CardTitle>
                            Tailoring the onboarding experience to users based
                            on their specific needs and skill levels.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card>
                            <CardTitle>Interactive Elements</CardTitle>
                            Incorporating tutorials or interactive guides to
                            further improve user engagement and retention.
                        </Card>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <PageEnd />
    </>
);
