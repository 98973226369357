import { useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { CheckPasscode, Footer, Navigation, Topbar } from './Components';
import { Modal, useModalContext } from './Modal';

// Deprecated
export const ContentsContainer = ({
    children,
    size = '1024',
    className = '',
    style = {},
}) => (
    <div
        className={`container d-flex justify-content-center px-m px-xl-0 ${className}`}
        style={style}>
        <div className="w-100" style={{ maxWidth: size + 'px' }}>
            {children}
        </div>
    </div>
);

export const Container = ({
    children,
    size = '800',
    className = '',
    style = {},
}) => (
    <div
        className={`d-flex justify-content-center px-m px-xl-0 py-xl2 ${className}`}
        style={style}>
        <div className="w-100" style={{ maxWidth: size + 'px' }}>
            {children}
        </div>
    </div>
);

export const Title = ({ title = '' }) => <h3 className="fw-bold">{title}</h3>;

export const RowContent = ({ children, title = '' }) => (
    <>
        <h3 className="fw-bolder py-l mb-0">{title}</h3>
        {children}
    </>
);

export const Row = ({ children }) => (
    <div className="row gx-xl gy-l">{children}</div>
);

export const Column = ({ children, className = '' }) => (
    <div className={`col ${className}`}>{children}</div>
);

export const Column6 = ({ children, className = '' }) => (
    <div className={`col-12 col-md-6 ${className}`}>{children}</div>
);

export const Column4 = ({ children, className = '' }) => (
    <div className={`col-12 col-lg-4 ${className}`}>{children}</div>
);

export const Column4Sm = ({ children, className = '' }) => (
    <div className={`col-12 col-sm-4 ${className}`}>{children}</div>
);

export const LineBreakMd = () => (
    <>
        {' '}
        <br className="d-none d-md-inline-block" />
    </>
);

export const Icon = ({ children }) => (
    <>
        <div className="d-flex flex-column align-items-center">{children}</div>
    </>
);

export const IconCaption = ({ children }) => (
    <span className="text-center">{children}</span>
);

export const CardTitle = ({ children }) => (
    <div className="d-flex justify-content-center pb-l fw-bold fs-m text-center">
        {children}
    </div>
);

export const Card = ({ title = '', children }) => (
    <div className="card p-xl h-100">
        {title ? <CardTitle>{title}</CardTitle> : null}
        {children}
    </div>
);

export const Subtitle = ({ title = '', className = '' }) => (
    <h5 className={`fw-bold lh-1600 ${className}`}>{title}</h5>
);

export const SmallCard = ({ title = '', children }) => (
    <div className="card p-m h-100">
        <Subtitle title={title} className="mb-s" />
        {children}
    </div>
);

export const Paragraph = ({ children, className = '' }) => (
    <p className={`py-xl mb-0 ${className}`}>{children}</p>
);

export const PageEnd = ({ className = '' }) => (
    <div className={`mb-xxxl ${className}`} />
);

export const PageLayout = () => {
    const modalContext = useModalContext();
    return (
        <>
            {modalContext.content === 'navigation' ? (
                <Modal>
                    <Navigation />
                </Modal>
            ) : null}
            <Topbar />
            <Outlet />
        </>
    );
};

export const LandingLayout = () => (
    <>
        <Outlet />
        <Footer />
    </>
);

export const ProjectLayout = () => {
    const modalContext = useModalContext();
    const [validated, validate] = useState(false);

    useEffect(() => {
        if (!validated) modalContext.on('checkpasscode');
        else modalContext.off();
        return () => {
            modalContext.off();
        };
    }, [validated]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!validated) {
        return (
            <>
                {modalContext.content === 'checkpasscode' ? (
                    <Modal>
                        <CheckPasscode validate={validate} />
                    </Modal>
                ) : null}
            </>
        );
    } else {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <div className="w-100">
                        <Outlet />
                    </div>
                </div>
                <Footer />
            </>
        );
    }
};
