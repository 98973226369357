import { Tag, ProjectBanner } from './Components';
import { NoFrame } from './Frames';
import { ReactComponent as Audit } from './img/svg/Audit.svg';
import { ReactComponent as ComponentCreation } from './img/svg/ComponentCreation.svg';
import { ReactComponent as UserResearch } from './img/svg/UserResearch.svg';
import Audit1 from './img/tab/audit1.png';
import Audit2 from './img/tab/audit2.png';
import Banner from './img/tab/banner.png';
import Exploration1 from './img/tab/exploration1.png';
import Exploration2 from './img/tab/exploration2.png';
import Exploration3 from './img/tab/exploration3.png';
import Exploration4 from './img/tab/exploration4.png';
import Process from './img/tab/process.png';
import {
    Row,
    PageEnd,
    Column6,
    Card,
    Container,
    RowContent,
    Paragraph,
    SmallCard,
    Icon,
    IconCaption,
    Column4,
    CardTitle,
    Subtitle,
    Column4Sm,
} from './Layout';
import Finalize from './video/tab/finalize.mov';
import Prototype1 from './video/tab/prototype1.mov';
import Prototype2 from './video/tab/prototype2.mov';

export const Tab = () => (
    <>
        <Container className="pb-s">
            <ProjectBanner
                src={Banner}
                alt="Tab"
                title="AutoCAD Tab Redesign, Use for multiple products"
            />
        </Container>

        <Container className="pt-s pb-s">
            <div className="row g-xs">
                <Tag text="Design System" />
                <Tag text="User Research" />
                <Tag text="Visual Design" />
                <Tag text="Prototyping" />
            </div>
        </Container>

        <Container>
            <RowContent title="Overview">
                <Row>
                    <div className="col">
                        <div className="py-m">
                            AutoCAD is a CAD and drafting tool that enables
                            users to create 2D, 3D precise drawings, commonly
                            used in architecture, engineering, and construction
                            industries. AutoCAD team is planning to refresh
                            their user interface with new theme colors with
                            improved component designs. <br />
                            <br />
                            Given the complexity of AutoCAD's unique design
                            pattern, I was tasked to redesign their tabs.
                            Furthermore, there was a potential to contribute to
                            the Weave, Autodesk design system, so other Autodesk
                            products can adapt this component pattern, aligning
                            the visual design.
                        </div>
                    </div>

                    <div className="col-12 col-sm-auto">
                        <div>
                            <SmallCard title="Duration">
                                Project initiation: Nov 2018 <br />
                                Implementation: Feb 2019
                            </SmallCard>
                        </div>
                    </div>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="My Role & Responsibilities">
                <Paragraph>
                    As the sole designer, I lead this project to drive the
                    redesign process from conception to implementation. This
                    includes conducting research, collaboration with other teams
                    to find common design patterns, creating new design
                    examples, facilitating feedback sessions, prototyping with
                    interactions and layout, and execution of the final design.
                </Paragraph>
                <Row>
                    <Column4Sm>
                        <Icon>
                            <Audit />
                            <IconCaption>Audit</IconCaption>
                        </Icon>
                    </Column4Sm>
                    <Column4Sm>
                        <Icon>
                            <UserResearch />
                            <IconCaption>User Research</IconCaption>
                        </Icon>
                    </Column4Sm>
                    <Column4Sm>
                        <Icon>
                            <ComponentCreation />
                            <IconCaption>Component Creation</IconCaption>
                        </Icon>
                    </Column4Sm>
                </Row>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Problems">
                <Row>
                    <Column4>
                        <Card>
                            <CardTitle>Complexity of tab</CardTitle>
                            Some of tab patterns in AutoCAD were too unique, and
                            it might be only used in AutoCAD. Ensuring that some
                            of designs can be product-specific design, had a
                            potential to become an exception and not contributed
                            to the Weave design system.
                        </Card>
                    </Column4>
                    <Column4>
                        <Card>
                            <CardTitle>Technical difficulty</CardTitle>
                            Current design don't have hover effect on tab. It
                            might be challenging to implement the redesigned tab
                            components since it may cause problem with existing
                            codebase.
                        </Card>
                    </Column4>
                    <Column4>
                        <Card>
                            <CardTitle>Cross-product compatibility</CardTitle>
                            Each Autodesk products has unique users from diverse
                            background and industries, with unique preferences
                            and workflows. The redesign needs to ensure
                            compatibility and consistency across different
                            products while still meeting specific requirements
                            from each product.
                        </Card>
                    </Column4>
                </Row>
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Design Process">
                <Paragraph>
                    The design process began with extensive research and
                    analysis. This included internal audits, understanding user
                    needs, and defining key features. In order to gain
                    comprehensive insights about the panel component, I
                    facilitated a collaborative sessions with designers from
                    both product teams and design system team. Through in-person
                    meetings, virtual workshops through Mural, or Zoom calls, I
                    identified current status, existing challenges, and their
                    expectations for the redesigned panel interface.
                    <br />
                    <br />
                    This process is to identify:
                    <ul>
                        <li>Deep understanding of users</li>
                        <li>Identify users pain points</li>
                        <li>Define common design pattern</li>
                        <li>Opportunities for improvement</li>
                    </ul>
                </Paragraph>
                <NoFrame src={Process} />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Audit">
                <Paragraph>
                    Firstly, I conducted research session with AutoCAD UX team
                    to understand AutoCAD's design pattern. I started to analyze
                    the existing tab design patterns to understand the function
                    and purpose of different tab styles. Some of tab patterns
                    were product-specific, so I collaborated with AutoCAD
                    research team to get deep insight by getting feedback and
                    user flows from external users. <br />
                    <br />
                    Dido Tsigaridi who is Sr. UX research, and Karen Mason who
                    is Sr.UX designer in AutoCAD Team did 1 hr long 1:1 sessions
                    with 5 customers to understand the current use of 'layout
                    tabs' and 'color tabs.
                    <br />
                    <br />
                    Since the redesign tab can be used in other Autodesk
                    products, I also did some audit asking other product teams
                    to provide all of their tab patterns, getting some insight
                    about the common design patterns.
                </Paragraph>
                <NoFrame src={Audit1} />
                <NoFrame src={Audit2} />
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Key Findings">
                <Row>
                    <Column6>
                        <Card title="Canvas Tabs">
                            Many Autodesk desktop products have canvas tab.
                            AutoCAD canvas tab had unique design because they
                            wanted to have visual separation to differentiate
                            the canvas tab from other tool tabs.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card title="Additional States">
                            Some of states from research are product-specific
                            features, but I still need to think about including
                            all of special states as optional states.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card title="Accessibility">
                            New tab design should be designed with
                            accessibility, ensuring that understandable for all
                            users including people with disabilities. This may
                            involve color contrast, keyboard navigation, or
                            screen compatibility.
                        </Card>
                    </Column6>
                    <Column6>
                        <Card title="Usability">
                            A lot of Autodesk desktop products are
                            information-dense applications, meaning that it is
                            common to have some usability issues such as crowded
                            designs with truncation options, small sizes and
                            spaces, or inconsistent behaviors.
                        </Card>
                    </Column6>
                </Row>
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Design Phase">
                <NoFrame src={Exploration1} />
                <NoFrame src={Exploration2} />
                <NoFrame src={Exploration3} />
                <NoFrame src={Exploration4} />
            </RowContent>
        </Container>

        <Container className="bg-teal">
            <RowContent title="Prototypes">
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${Prototype1}#t=0.001`}
                    type="video/mp4"
                />
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${Prototype2}#t=0.001`}
                    type="video/mp4"
                />
            </RowContent>
        </Container>

        <Container>
            <RowContent title="Finalize">
                <video
                    className="w-100 mt-m"
                    controls
                    autoPlay={false}
                    src={`${Finalize}#t=0.001`}
                    type="video/mp4"
                />
            </RowContent>
        </Container>

        <Container className="bg-gray01">
            <RowContent title="Relection">
                <Paragraph>
                    <Subtitle title="Collaboration matters!" />
                    This project gave me valuable lesson about effective
                    collaboration across cross-functional teams including
                    product designers, developers, researchers, and managers. I
                    spent lots of time participating in meetings, making sure to
                    align the project goals from diverse perspectives.
                    <br /> <br />
                    <Subtitle title="Adaptability and flexibility" />
                    As I spent lots of time exploring and understanding other
                    products, I recognized the diverse needs and preferences
                    from different users. It highlighted the importance of
                    making flexible, adaptable component in order to become a
                    component for multi-brand design system.
                    <br /> <br />
                    During this project, I was able to lead from initiating
                    concept, ideas, collaboration with other teams, facilitating
                    feedback sessions, building prototype, to executing the
                    final design. Through leading this project, I gained
                    valuable experience in all aspects of the design process.
                </Paragraph>
            </RowContent>
        </Container>

        <PageEnd />
    </>
);
